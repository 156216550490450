import { useEffect, useMemo } from 'react';
import Auswahl from '../components/Auswahl';
import Eingabe from '../components/Eingabe';
import InfoDrei from '../components/InfoDrei';
import InfoTabelle from '../components/InfoTabelle';
import * as CONST from '../Constants.js';



const konstante = (i) => (Math.log(2) / (0.5 + 0.01 * i)).toFixed(4)
const computecmax = (a, d, w, i) => (a / d) * (1. / (w * konstante(i))) * (1. - Math.exp(-konstante(i) * d))
const ct = (c, i, t, d) => c * Math.exp(-konstante(i) * (t - d))

const computationloop = (a, w, t, d) => {
    let c = []
    let cmax = null
    for (let i = 0; i < 773; i++) {
        cmax = computecmax(a, d, w, i).toFixed(1)
        c.push(ct(cmax, i, t, d).toFixed(1))
    }
    console.debug(c)
    return c
}

export default function ErstDosisScreen({
    language, setScreen,
    infusionsdauer, setInfusionsdauer,
    erstdosis, setErstDosis,
    geplanteErhaltungsdosis, setGeplanteErhaltungsdosis,
    geplanteInfusionsdauer, setGeplanteInfusionsdauer,
    serumspiegel, setSerumspiegel,
    stundennachgabe, setStundennachGabe,
    geschaetzteHalbwertszeit, setGeschaetzteHalbwertszeit,
    setUnitGroesse,
    E_verteilungsvolumen, 
    n_MHK
}) {

    
    const cmaxlist = useMemo(() =>
        computationloop(erstdosis, E_verteilungsvolumen, stundennachgabe, infusionsdauer),
        [erstdosis, stundennachgabe, infusionsdauer, E_verteilungsvolumen])

    const pos = cmaxlist.findIndex((value) => value >= parseFloat(serumspiegel))

    /*  const index = useMemo(() => cmaxlist.reduce((prev, curr) => 
    (Math.abs(curr - parseFloat(serumspiegel)) <= Math.abs(prev - parseFloat(serumspiegel)) ? 
    curr : prev)), [cmaxlist, serumspiegel])
     console.debug(index) */

    useEffect(() => {
        if (!!erstdosis && !!stundennachgabe && !!infusionsdauer && !!serumspiegel) {
            setGeschaetzteHalbwertszeit(0.5 + pos * 0.01)
        } else {
            setGeschaetzteHalbwertszeit(null)
        }
    }, [pos])
    const eliminationskonstante = Math.log(2) / geschaetzteHalbwertszeit




    const computeTalspiegel = (stunden) => {
        const rate = parseFloat(geplanteErhaltungsdosis) / parseFloat(geplanteInfusionsdauer)
        const anteil = E_verteilungsvolumen * eliminationskonstante
        let ergebnis = ((rate / anteil)
            * (1. - Math.exp(-eliminationskonstante * parseFloat(geplanteInfusionsdauer)))
            * Math.exp(-eliminationskonstante * (6 * stunden - parseFloat(geplanteInfusionsdauer))))
            + ((rate / anteil)
                * (1. - Math.exp(-eliminationskonstante * parseFloat(geplanteInfusionsdauer)))
                * Math.exp(-eliminationskonstante * (5 * stunden - parseFloat(geplanteInfusionsdauer))))
            + ((rate / anteil)
                * (1. - Math.exp(-eliminationskonstante * parseFloat(geplanteInfusionsdauer)))
                * Math.exp(-eliminationskonstante * (4 * stunden - parseFloat(geplanteInfusionsdauer))))
            + ((rate / anteil)
                * (1. - Math.exp(-eliminationskonstante * parseFloat(geplanteInfusionsdauer)))
                * Math.exp(-eliminationskonstante * (3 * stunden - parseFloat(geplanteInfusionsdauer))))
            + ((rate / anteil)
                * (1. - Math.exp(-eliminationskonstante * parseFloat(geplanteInfusionsdauer)))
                * Math.exp(-eliminationskonstante * (2 * stunden - parseFloat(geplanteInfusionsdauer))))
            + ((rate / anteil)
                * (1. - Math.exp(-eliminationskonstante * parseFloat(geplanteInfusionsdauer)))
                * Math.exp(-eliminationskonstante * (1 * stunden - parseFloat(geplanteInfusionsdauer))));
        ergebnis = Math.round(ergebnis * 10) / 10;
        return ergebnis
    }

    const ergebnisse = [
        [4, 6, 8, 12, 18, 24],
        [
            computeTalspiegel(4), computeTalspiegel(6), computeTalspiegel(8),
            computeTalspiegel(12), computeTalspiegel(18), computeTalspiegel(24)],
        [
            Math.round((computeTalspiegel(4) / n_MHK) * 10) / 10,
            Math.round((computeTalspiegel(6) / n_MHK) * 10) / 10,
            Math.round((computeTalspiegel(8) / n_MHK) * 10) / 10,
            Math.round((computeTalspiegel(12) / n_MHK) * 10) / 10,
            Math.round((computeTalspiegel(18) / n_MHK) * 10) / 10,
            Math.round((computeTalspiegel(24) / n_MHK) * 10) / 10
        ]
    ]
    
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: CONST.SCREENWIDTH,
            marginTop: CONST.MARGINGROSS,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>

            <Eingabe titel={language ? 'Dose' : 'Erstdosis'} optionArray={['250-3000']}
                setVariable={setErstDosis} variable={erstdosis} min={250} max={3000} unit={['mg']} faktor={[1]} />
            <Eingabe titel={language ? 'Infusion time' : 'Infusionsdauer'} optionArray={['0.1-24']} decimals={1}
                setVariable={setInfusionsdauer} variable={infusionsdauer} min={0.1} max={24} unit={['h']} faktor={[1]} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Eingabe titel={language ? 'Serum level' : 'Serumspiegel'} klein optionArray={['0.1-100']} decimals={1}
                    setVariable={setSerumspiegel} variable={serumspiegel} unit={['mg/l']} min={0.1} max={100} faktor={[1]} />
                <Eingabe titel={language ? 'Time from start to level' : 'Stunden nach Gabe'} klein decimals={1}
                    optionArray={['0-100000']} setVariable={setStundennachGabe} variable={stundennachgabe}
                    min={0.1} max={100000}
                    setunit={setUnitGroesse} unit={['h']} faktor={[1]} />
            </div>

            <Eingabe titel={language ? 'Scheduled dose' : 'Geplante Erhaltungsdosis'} optionArray={['250-3000']} //TODO: Übersetzung
                setVariable={setGeplanteErhaltungsdosis} variable={geplanteErhaltungsdosis} min={250} max={3000} unit={['mg']} faktor={[1]} />
            <Eingabe titel={language ? 'Scheduled infusion time' : 'Geplante Infusionsdauer'} optionArray={['0.1-4']} //TODO: Übersetzung
                setVariable={setGeplanteInfusionsdauer} variable={geplanteInfusionsdauer} decimals={1} min={0.1} max={4} unit={['h']} faktor={[1]} />

            {/*        {warnung && //talspiegel && stundennachgabe && dosis && dosisintervall &&

            <div style={{
                //position: 'absolute',  
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
                padding: PADDINGGROSS,
                margin: MARGINKLEIN,
                borderRadius: 10,
                color: 'white', backgroundColor: '#A72134'
            }}>
                <h2>
                    {language ? 'WARNING' : 'WARNHINWEIS'}
                </h2>

                {((((dosis * 24 / dosisintervall) / INDvancomycinClearance) < 400) || ((dosis * 24 / dosisintervall) / INDvancomycinClearance) > 600) &&
                    <p> {language ? 'Dosing outside target range' : 'AUC nicht im angestrebten Bereich'} </p>
                }

                {((INDvancomycinClearance < vancomycinCl * 0.75) || (INDvancomycinClearance > vancomycinCl * 1.5)) &&
                    <div style={{ fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
                        <p>{language ? 'Dose administered?' : 'Dosis gegeben?'}</p>
                        <p>{language ? 'Dose omitted?' : 'Dosis vergessen?'}</p>
                        <p>{language ? 'Time of blood sampling?' : 'Zeitpunkt der Blutabnahme?'}</p>
                        <p>{language ? 'Sample take from access?' : 'Abnahme aus Zugang?'}</p>
                        <p>{language ? 'Right patient?' : 'Patient verwechselt?'}</p>
                    </div>
                }





                <div
                    className='weiterbutton'
                    onClick={() => {
                        setWarnung(false)
                    }}> OK </div>


            </div>} */}

            {geplanteErhaltungsdosis && geplanteInfusionsdauer && serumspiegel && stundennachgabe &&
                <InfoTabelle titel={language ? 'New dose' : 'Neue Dosierung'}
                    infos={[language ? 'Interval' : 'Dosisintervall', language ? 'Through level' : 'Talspiegel', 'cmin/MHK']} //TODO: Übersetzung
                    colors={[
                        (ergebnisse[2][0] >= 1 && ergebnisse[2][0] <= 2) ? CONST.GREEN :
                            (ergebnisse[2][0] >= 2 && ergebnisse[2][0] <= 4) ? CONST.YELLOW : CONST.RED,
                        (ergebnisse[2][1] >= 1 && ergebnisse[2][1] <= 2) ? CONST.GREEN : (ergebnisse[2][1] >= 2 && ergebnisse[2][1] <= 4) ? CONST.YELLOW : CONST.RED,
                        (ergebnisse[2][2] >= 1 && ergebnisse[2][2] <= 2) ? CONST.GREEN : (ergebnisse[2][2] >= 2 && ergebnisse[2][2] <= 4) ? CONST.YELLOW : CONST.RED,
                        (ergebnisse[2][3] >= 1 && ergebnisse[2][3] <= 2) ? CONST.GREEN : (ergebnisse[2][3] >= 2 && ergebnisse[2][3] <= 4) ? CONST.YELLOW : CONST.RED,
                        (ergebnisse[2][4] >= 1 && ergebnisse[2][4] <= 2) ? CONST.GREEN : (ergebnisse[2][4] >= 2 && ergebnisse[2][4] <= 4) ? CONST.YELLOW : CONST.RED,
                        (ergebnisse[2][5] >= 1 && ergebnisse[2][5] <= 2) ? CONST.GREEN : (ergebnisse[2][5] >= 2 && ergebnisse[2][5] <= 4) ? CONST.YELLOW : CONST.RED,
                    ]}
                    ergebnisse={ergebnisse}
                    units={['h', 'mg/l', '']} />}

            <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div
                    className='weiterbutton'
                    onClick={() => {
                        setScreen(2)
                    }}> {language ? 'Back' : 'Zurück'}
                </div>
            </div>


        </div>
    )
}