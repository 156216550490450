import { useState, useEffect } from 'react';
import Auswahl from '../components/Auswahl';
import Eingabe from '../components/Eingabe';
import InfoDrei from '../components/InfoDrei';
import InfoTabelle from '../components/InfoTabelle';
import Warnhinweis from '../components/Warnhinweis';
import * as CONST from '../Constants.js';

export default function DauerinfusionScreen({
    language, setScreen,
    serumspiegel, setSerumspiegel,
    infusionsdauer, setInfusionsdauer,
    laufrate, setLaufrate,
    dosisintervall, setDosisIntervall,
    infusionsdosis, setInfusionsdosis,
    n_MHK
}) {
    useEffect(() => {
        setScreen(5)
    }, [])


    const [dosrate, setDosrate] = useState(false);

    const computeDosis = (m) => {
            console.debug('computeDosis', serumspiegel, laufrate, infusionsdosis, n_MHK, m, infusionsdosis / serumspiegel * n_MHK * m)
        const ergebnis = laufrate ? (((laufrate * 1000) / 2.1) / serumspiegel * n_MHK * m) :
            (infusionsdosis ? (infusionsdosis / serumspiegel * n_MHK * m) : 0)
            console.debug('ergebnis', (((laufrate * 1000) / 2.1) / serumspiegel * n_MHK * m), (infusionsdosis / serumspiegel * n_MHK * m    ) )
        return Math.round(ergebnis / 100) * 100
    }

    useEffect(() => {
        const computeLaufrate = (dosis) => {
            let result = null
            result = dosis * 2.1 / 1000
            result = (Math.round(result * 100) / 100).toString();
            return result
        }
        if (dosrate == 0) {
            setLaufrate(computeLaufrate(infusionsdosis).toString())
        }
    }, [infusionsdosis, serumspiegel])

    useEffect(() => {
        const computeInfDosis = (laufrate) => {
            let result = null
            result = laufrate * 1000 / 2.1
            result = (Math.round(result / 100) * 100).toFixed(0);
            return result
        }
        if (dosrate == 1) {
            setInfusionsdosis(computeInfDosis(laufrate).toString())
        }

    }, [laufrate, serumspiegel])


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: CONST.SCREENWIDTH,
            marginTop: CONST.MARGINGROSS,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>
            <Eingabe titel={language ? 'Serum level' : 'Serumspiegel'} optionArray={['0.1-100']} decimals={1}
                setVariable={setSerumspiegel} variable={serumspiegel} unit={['mg/l']} min={0.09} max={100} faktor={[1]} />

            <Auswahl titel={''} optionArray={[language ? 'Dose' : 'Dosis', language ? 'Infusion rate' : 'Laufrate']}
                setVariable={setDosrate} variable={dosrate} />
            {dosrate == 0 &&
                <Eingabe titel={language ? 'Dose' : 'Infusionsdosis'} optionArray={['500-12000']}
                    setVariable={setInfusionsdosis} variable={infusionsdosis} min={500} max={12000} unit={['mg']} faktor={[1]} />
            }

            {dosrate == 1 &&
                <Eingabe titel={language ? 'Infusion rate' : 'Laufrate'} optionArray={['0-500']} decimals={1}
                    setVariable={setLaufrate} variable={laufrate} min={0.1} max={500} unit={['ml/h']} faktor={[1]} />}


            {serumspiegel && (infusionsdosis || laufrate != 0 ) &&
                <InfoDrei titel={language ? 'Alternate dose' : 'Neue Dosierung'}
                    infos={['4 x MHK', language ? 'Dose' : 'Erhaltungsdosis', '8 x MHK']}
                    ergebnisse={[
                        computeDosis(4),
                        computeDosis(5),
                        computeDosis(8)
                    ]}
                    extra={true}
                    units={['mg/24h', 'mg/24h', 'mg/24h']} />}




            <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div
                    className='weiterbutton'
                    style={{ display: 'flex', }}
                    onClick={() => {
                        setScreen(2)
                    }}> {language ? 'Back' : 'Zurück'} </div>
            </div>




        </div>
    )
}