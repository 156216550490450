import Auswahl from '../components/Auswahl';
import Eingabe from '../components/Eingabe';
import InfoDrei from '../components/InfoDrei';
import InfoTabelle from '../components/InfoTabelle';
import InfoParameter from '../components/InfoParameter';
import * as CONST from '../Constants.js';

export default function ParameterScreen({
    language, screen, setScreen,
    P_verteilungsvolumen, P_meropenemhalbwertszeit, P_meropenemCl,
    E_verteilungsvolumen, E_meropenemhalbwertszeit, E_meropenemCl,
    geschaetzteHalbwertszeit, gemesseneMeropenemCl, eliminationskonstante,
    A_geschaetzteHalbwertszeit, A_gemesseneMeropenemCl, A_eliminationskonstante,
    cminMHK, talspiegel, spitzenspiegel, serumspiegel,stundennachgabe,
    cMHK, D_gemesseneMeropenemCl
}) {


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: CONST.SCREENWIDTH,
            marginTop: CONST.MARGINGROSS,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>
            <InfoParameter
                titel={language ? 'Population data' : 'Populationsparameter'}
                infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', language ? 'Half-life ' : 'Halbwertszeit', 'Meropenem Cl']}
                ergebnisse={[P_verteilungsvolumen, P_meropenemhalbwertszeit.toFixed(1), (Math.round(P_meropenemCl*10)/10).toFixed(1)]}
                units={['l/kg', 'h', 'l/h']} />
            <InfoParameter
                titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Erwartungswerte'}
                infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', language ? 'Half-life ' : 'Halbwertszeit', 'Meropenem Cl']}
                ergebnisse={[Math.round(E_verteilungsvolumen), (Math.round(E_meropenemhalbwertszeit * 10) / 10).toFixed(1), Math.round(E_meropenemCl * 10) / 10]}
                units={['l', 'h', 'l/h']} />

            {screen == 3 && !!geschaetzteHalbwertszeit &&
                <InfoParameter
                    titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                    infos={[language ? 'Half-life ' : 'Halbwertszeit', 'Meropenem Cl', language ? 'Elimination constant' : 'Eliminationskonstante',]}
                    colors={[
                        '',
                        (gemesseneMeropenemCl < 0.5 * E_meropenemCl || gemesseneMeropenemCl > 1.5 * E_meropenemCl) ? '#A72134' : '']}
                    ergebnisse={[
                       geschaetzteHalbwertszeit? (Math.round(geschaetzteHalbwertszeit * 10) / 10).toFixed(1) : '--',
                       gemesseneMeropenemCl ? Math.round(gemesseneMeropenemCl * 10) / 10 : '--',
                       eliminationskonstante ? Math.round(eliminationskonstante * 1000) / 1000 : '--'
                    ]}
                    units={['h', 'l/h', '1/h']} />}

            {screen == 4 && cminMHK != null && serumspiegel  && stundennachgabe &&
                <InfoParameter
                    titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                    infos={['cmin/MHK ',
                        language ? 'Half-life ' : 'Halbwertszeit',
                        'Meropenem Cl',
                        language ? 'Elimination constant' : 'Eliminationskonstante',
                        language ? 'Through level ' : 'Talspiegel',
                        language ? 'Peak level ' : 'Spitzenspiegel',
                    ]}
                    ergebnisse={[
                        cminMHK,
                       ( Math.round(A_geschaetzteHalbwertszeit * 10) / 10).toFixed(1),
                        Math.round(A_gemesseneMeropenemCl * 10) / 10,
                        Math.round(A_eliminationskonstante * 1000) / 1000,
                        talspiegel ? talspiegel.toFixed(1) : '--',
                        spitzenspiegel ? spitzenspiegel.toFixed(1) : '--'
                    ]}
                    units={['', 'h', 'l/h','1/h', 'mg/l', 'mg/l']} />}

            {screen == 5 && !!cMHK  && 
                <InfoParameter
                    titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                    infos={[
                        'c/MHK ',
                        'Meropenem Cl'
                    ]}
                    colors={[
                        (cMHK >= 4 && cMHK <= 8) ? 'rgba(122, 122, 122, 0.333)' : '#A72134',
                        // (cMHK >= 4 && cMHK <= 6) ? '#39a760' : (cMHK > 6 && cMHK <= 8) ? '#dfaf68' : '#A72134',
                        (D_gemesseneMeropenemCl < 0.5 * E_meropenemCl || D_gemesseneMeropenemCl > 1.5 * E_meropenemCl) ? '#A72134' : '']}
                    ergebnisse={[
                        cMHK ? cMHK : '--',
                        D_gemesseneMeropenemCl ?  Math.round(D_gemesseneMeropenemCl * 10) / 10 : '--',
                    ]}
                    units={['', 'l/h', '1/h']} />}

        </div>
    )
}