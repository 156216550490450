import { useState, useEffect, useMemo } from 'react';
import Auswahl from '../components/Auswahl';
import Eingabe from '../components/Eingabe';
import InfoDrei from '../components/InfoDrei';
import InfoTabelle from '../components/InfoTabelle';
import * as CONST from '../Constants.js';

const konstante = (i) => (Math.log(2) / (0.5 + 0.01 * i)).toFixed(4)
const computecmax = (erhaltungsdosis, infusionsdauer, E_verteilungsvolumen, dosisintervall, i) => {
    const rate = parseFloat(erhaltungsdosis) / parseFloat(infusionsdauer)
    const anteil = E_verteilungsvolumen * konstante(i)
    let ergebnis = ((rate / anteil)
        * (1. - Math.exp(-konstante(i) * parseFloat(infusionsdauer)))
        * Math.exp(-konstante(i) * (6 * dosisintervall - parseFloat(infusionsdauer))))
        + ((rate / anteil)
            * (1. - Math.exp(-konstante(i) * parseFloat(infusionsdauer)))
            * Math.exp(-konstante(i) * (5 * dosisintervall - parseFloat(infusionsdauer))))
        + ((rate / anteil)
            * (1. - Math.exp(-konstante(i) * parseFloat(infusionsdauer)))
            * Math.exp(-konstante(i) * (4 * dosisintervall - parseFloat(infusionsdauer))))
        + ((rate / anteil)
            * (1. - Math.exp(-konstante(i) * parseFloat(infusionsdauer)))
            * Math.exp(-konstante(i) * (3 * dosisintervall - parseFloat(infusionsdauer))))
        + ((rate / anteil)
            * (1. - Math.exp(-konstante(i) * parseFloat(infusionsdauer)))
            * Math.exp(-konstante(i) * (2 * dosisintervall - parseFloat(infusionsdauer))))
        + ((rate / anteil)
            * (1. - Math.exp(-konstante(i) * parseFloat(infusionsdauer))));
    ergebnis = Math.round(ergebnis * 10) / 10;
    return ergebnis
}
const ct = (c, i, t, d) => c * Math.exp(-konstante(i) * (t - d))

const computationloop = (a, d, w, di, t) => {
    let c = []
    let cmax = null
    for (let i = 0; i < 773; i++) {
        cmax = computecmax(a, d, w, di, i).toFixed(1)
        c.push(ct(cmax, i, t, d).toFixed(1))
    }
    console.debug(c)
    return c
}

export default function SteadyStateScreen({
    language, setScreen,
    infusionsdauer, setInfusionsdauer,
    erhaltungsdosis, setErhaltungsdosis,
    dosisintervall, setDosisIntervall,
    geplanteErhaltungsdosis, setGeplanteErhaltungsdosis,
    geplanteInfusionsdauer, setGeplanteInfusionsdauer,
    neueErhaltungsdosis, setNeueErhaltungsdosis,
    neueInfusionsdauer, setNeueInfusionsdauer,
    geschaetzteHalbwertszeit, setGeschaetzteHalbwertszeit,
    cminMHK, talspiegel, MHK,
    computeTalspiegel,
    serumspiegel, setSerumspiegel,
    stundennachgabe, setStundennachGabe,
    setUnitGroesse,
    E_verteilungsvolumen
}) {
    useEffect(() => {
        setScreen(4)
    }, [])
    const [option, setOption] = useState(false);

    const cmaxlist =  computationloop(erhaltungsdosis, infusionsdauer, E_verteilungsvolumen, dosisintervall, stundennachgabe)
    const pos = cmaxlist.findIndex((value) => value >= parseFloat(serumspiegel))

    useEffect(() => {
        setGeschaetzteHalbwertszeit(0.5 + pos * 0.01)
    }, [pos])

    console.debug('gesch. HWZ',(0.5 + pos * 0.01).toFixed(2))

    const ergebnisse = [
        [4, 6, 8, 12, 18, 24],
        [
            computeTalspiegel(4).toFixed(1), computeTalspiegel(6).toFixed(1), computeTalspiegel(8).toFixed(1),
            computeTalspiegel(12).toFixed(1), computeTalspiegel(18).toFixed(1), computeTalspiegel(24).toFixed(1)
        ],
        [
            (computeTalspiegel(4) / MHK).toFixed(1),
            (computeTalspiegel(6) / MHK).toFixed(1),
            (computeTalspiegel(8) / MHK).toFixed(1),
            (computeTalspiegel(12) / MHK).toFixed(1),
            (computeTalspiegel(18) / MHK).toFixed(1),
            (computeTalspiegel(24) / MHK).toFixed(1)
        ]
    ]

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>

            <div style={{
                display: 'flex', width: CONST.SCREENWIDTH,
                flexDirection: 'column',
      
                marginTop: CONST.MARGINGROSS,
            }}>
                <Eingabe titel={language ? 'Dose' : 'Erhaltungsdosis'} optionArray={['250-3000']}
                    setVariable={setErhaltungsdosis} variable={erhaltungsdosis} min={250} max={3000} unit={['mg']} faktor={[1]} />
                <Eingabe titel={language ? 'Infusion time' : 'Infusionsdauer'} optionArray={['0-24']}
                    setVariable={setInfusionsdauer} variable={infusionsdauer} min={0.1} max={24} unit={['h']} faktor={[1]} decimals={1} />
                <Eingabe titel={language ? 'Interval' : 'Dosisintervall'} optionArray={['4-48']}
                    setVariable={setDosisIntervall} variable={dosisintervall} min={4} max={48} unit={['h']} faktor={[1]} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Eingabe titel={language ? 'Serum level' : 'Serumspiegel'} klein optionArray={['0.1-100']} decimals={1}
                        setVariable={setSerumspiegel} variable={serumspiegel} unit={['mg/l']} min={0.09} max={100} faktor={[1]} />
                    <Eingabe titel={language ? 'Time from start of dose to level' : 'Stunden nach Gabe'} klein decimals={1}
                        optionArray={['0-100000']} setVariable={setStundennachGabe} variable={stundennachgabe}
                        min={0.1} max={100000}
                        setunit={setUnitGroesse} unit={['h']} faktor={[1]} />
                </div>

                {cminMHK != null && stundennachgabe  && serumspiegel  &&
                    <InfoDrei titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                        infos={['cmin/MHK', language ? 'Through level' : 'Talspiegel']}
                        colors={[(cminMHK >= 1 && cminMHK <= 2) ? CONST.GREEN : (cminMHK > 2 && cminMHK <= 4) ? '#dfaf68' : '#A72134', '']}
                        ergebnisse={[cminMHK.toFixed(1), talspiegel.toFixed(1) ]}
                        units={['', 'mg/l']} />}

                {stundennachgabe && //TODO: Check
                    <div style={{ display: 'flex', justifyContent: 'end', width: '80%', fontSize: CONST.SCHRIFTMITTEL, marginBottom: -4 }}>
                        Optional:
                    </div>}

                <div style={{ display: 'flex', width: stundennachgabe && serumspiegel ? '100%' : '50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div
                        className='weiterbutton'
                        style={{ display: 'flex', }}
                        onClick={() => {
                            setScreen(2)
                        }}> {language ? 'Back' : 'Zurück'} </div>

                    {stundennachgabe && serumspiegel &&
                        <div className='weiterbutton'
                            style={option ? { backgroundColor: CONST.GREEN, } : {}}
                            onClick={() => {
                                setOption(!option)
                            }}>  {language ? 'New dose' : 'Neue Dosierung'} </div>}
                </div>
            </div>

            {option &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: CONST.SCREENWIDTH,
                    marginTop: CONST.MARGINGROSS,
                }}>
                    <Eingabe titel={language ? 'New dose' : 'Neue Erhaltungsdosis'} optionArray={['250-3000']} //TODO: Übersetzung
                        setVariable={setNeueErhaltungsdosis} variable={neueErhaltungsdosis} min={250} max={3000} unit={['mg']} faktor={[1]} />

                    <Eingabe titel={language ? 'New infusion time' : 'Neue Infusionsdauer'} optionArray={['0-4']} decimals={1} //TODO: Übersetzung
                        setVariable={setNeueInfusionsdauer} variable={neueInfusionsdauer} min={0.1} max={dosisintervall??4} unit={['h']} faktor={[1]} />


                    {neueErhaltungsdosis && neueInfusionsdauer && serumspiegel && stundennachgabe &&
                        <InfoTabelle titel={language ? 'New dose' : 'Neue Dosierung'}
                            infos={[language ? 'Interval' : 'Dosisintervall', language ? 'Through level' : 'Talspiegel', 'cmin/MHK']} //TODO: Übersetzung
                            colors={[
                                (ergebnisse[2][0] >= 1 && ergebnisse[2][0] <= 2) ? CONST.GREEN : 
                                (ergebnisse[2][0] >= 2 && ergebnisse[2][0] <= 4) ? CONST.YELLOW : CONST.RED,
                                (ergebnisse[2][1] >= 1 && ergebnisse[2][1] <= 2) ? CONST.GREEN :  (ergebnisse[2][1] >= 2 && ergebnisse[2][1] <= 4) ? CONST.YELLOW : CONST.RED,
                                (ergebnisse[2][2] >= 1 && ergebnisse[2][2] <= 2) ? CONST.GREEN :  (ergebnisse[2][2] >= 2 && ergebnisse[2][2] <= 4) ? CONST.YELLOW : CONST.RED,
                                (ergebnisse[2][3] >= 1 && ergebnisse[2][3] <= 2) ? CONST.GREEN :  (ergebnisse[2][3] >= 2 && ergebnisse[2][3] <= 4) ? CONST.YELLOW : CONST.RED,
                                (ergebnisse[2][4] >= 1 && ergebnisse[2][4] <= 2) ? CONST.GREEN :  (ergebnisse[2][4] >= 2 && ergebnisse[2][4] <= 4) ? CONST.YELLOW : CONST.RED,
                                (ergebnisse[2][5] >= 1 && ergebnisse[2][5] <= 2) ? CONST.GREEN :  (ergebnisse[2][5] >= 2 && ergebnisse[2][5] <= 4) ? CONST.YELLOW : CONST.RED,
                            ]}
                            ergebnisse={ergebnisse}
                            units={['h', 'mg/l', '']} />}
                </div>}
        </div>
    )
}