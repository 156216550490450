import Auswahl from '../components/Auswahl';
import Eingabe from '../components/Eingabe';
import InfoDrei from '../components/InfoDrei';
import * as CONST from '../Constants.js';

export default function SpiegelScreen({
    language, setScreen,
    E_meropenemCl,
    meropenemspiegel, setMeropenemspiegel,
    serumkreatinin, eGFR,
    steady, setSteady,
    initialdosis,
    medikation, setMedikation,
    MHK, setMHK,
}) {


    const computeErhaltungsdosis = (mero_cl) => {
        let result = null
        result = 10 * mero_cl * 24
        result = (Math.round(result / 100) * 100).toFixed(0);
        return result
    }

    const computeNeueErhaltungsdosis = (mero_cl) => {
        /*    =  WENN(J11<6;"1000mg/4h";
                WENN(J11<8;"1000mg/6h";
                WENN(J11<12;"1000mg/8h";
                WENN(J11<18;"1000mg/12h";
                WENN(J11<24;"1000mg/18h";
                "1000mg/24h"))))) */
        let hilf = 13 / mero_cl * 8 //woher kommt 8?
        let result = null

        if (hilf < 6) {
            result = "1000 mg/4h"
        } else if (hilf < 8) {
            result = "1000 mg/6h"
        } else if (hilf < 12) {
            result = "1000 mg/8h"
        } else if (hilf < 18) {
            result = "1000 mg/12h"
        } else if (hilf < 24) {
            result = "1000 mg/18h"
        } else {
            result = "1000 mg/24h"
        }

        return result
    }

    const computedInitialDosis = Math.round(initialdosis / 100) * 100
    const infusionsdauer = 15
    const infusionsrate = 25 * (60 / infusionsdauer)
    const computedNeueErhaltungsdosis = computeNeueErhaltungsdosis(E_meropenemCl)
    const computedErhaltungsdosis = computeErhaltungsdosis(E_meropenemCl)

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: CONST.SCREENWIDTH,
            marginTop: CONST.MARGINGROSS,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white',
        }}>

            <Auswahl titel={language ? 'Meropenem blood level' : 'Meropenemspiegel'}
                optionArray={[language ? 'Yes' : 'Ja', language ? 'No' : 'Nein']}
                setVariable={setMeropenemspiegel} variable={meropenemspiegel} />

            {meropenemspiegel == 0 &&
                <Auswahl titel={'MHK (mg/l)'} optionArray={[1, 2, 4, 8]} setVariable={setMHK} variable={MHK} />}

            {meropenemspiegel == 0 &&
                <Auswahl hoch titel={''} optionArray={[language ? 'Intermittent infusion' : 'Intermittierende Gabe',
                language ? 'Continuous infusion' : 'Dauerinfusion']}
                    setVariable={setMedikation} variable={medikation} />}

            {meropenemspiegel == 0 && medikation == 0 &&
                <Auswahl hoch titel={''}
                    optionArray={[language ? 'First dose' : 'Erstdosis', language ? 'Steady-state' : 'Steady State']}
                    setVariable={setSteady} variable={steady} />}


            {meropenemspiegel == 1 &&
                <InfoDrei titel={language ? 'Intermittent infusion' : 'Intermittierende Gabe - empirisch'}
                    infos={[language ? 'Dose' : 'Erhaltungsdosis']} //TODO: Übersetzung
                    ergebnisse={[computedNeueErhaltungsdosis]}
                    units={['']}
                    zusatz={language ? ('Loading dose ' + initialdosis / 2 + ' mg, Infusion time ' + infusionsdauer + ' min')
                        : ('Initialdosis ' + initialdosis + ' mg, Infusionsdauer ' + infusionsdauer + ' min')} />}

            {meropenemspiegel == 1 &&
                <InfoDrei titel={language ? 'Continuous infusion' : 'Dauerinfusion - empirisch'}
                    infos={['4 x MHK', language ? 'Dose' : 'Erhaltungsdosis', '8 x MHK']} //TODO: Übersetzung
                    ergebnisse={[
                        Math.round((8 * E_meropenemCl * 24) / 100) * 100,
                        computedErhaltungsdosis,
                        Math.round((16 * E_meropenemCl * 24) / 100) * 100]}
                    units={['mg/24h', 'mg/24h', 'mg/24h']}
                    zusatz={language ? ('Loading dose ' + computedInitialDosis + ' mg (' + infusionsrate + 'ml/min), infusion time ' + infusionsdauer + ' min')
                        : ('Initialdosis ' + computedInitialDosis / 2 + ' mg (' + infusionsrate + 'ml/min), Infusionsdauer ' + infusionsdauer + ' min')}
                    extra={language ? ('Standard perfusor (1g/50ml)')
                        : ('(Standardperfusor 1g/50ml)')} />}

            {meropenemspiegel == 1 && <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '328px',
                height: '72px',
                padding: CONST.PADDINGKLEIN,
                margin: CONST.MARGINKLEIN,
                backgroundColor: '#5555',
                borderRadius: 10,
                justifyContent: 'center',
            }}>
                {language == 0 &&
                    <div style={{ display: 'flex', textAlign: 'center', fontFamily: 'Inter', margin: CONST.MARGINKLEIN, fontSize: CONST.SCHRIFTKLEIN }}>
                        Hinweis: Bei Infektionen mit i-getesteten Keimen oder ZNS-Infektionen Verdopplung der Initialdosis + Erhaltungsdosis (Zielwerte: c/MHK=8-12  bei Dauerinfusion, cmin/MHK= 2-4 bei intermittierender Gabe)
                    </div>}
                {language == 1 &&
                    <div style={{ display: 'flex', textAlign: 'center', fontFamily: 'Inter', margin: CONST.MARGINKLEIN, fontSize: CONST.SCHRIFTKLEIN }}>
                        Notice: For infections with intermediate susceptible pathogens or CNS infections, doubling the loading dose + maintenance dose is recommended (Target range: 	c/MIC=8-12 with continuous infusion, cmin/MIC=2-4 with intermittent infusion)
                    </div>}

            </div>}


            {medikation == null || !(serumkreatinin || eGFR) || meropenemspiegel == 1 &&
                <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div
                        className='weiterbutton'
                        onClick={() => {
                            setScreen(0)
                        }}> {language ? 'Back' : 'Zurück'}
                    </div>
                </div>
            }

            {medikation != null && (serumkreatinin || eGFR) && meropenemspiegel == 0 &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div
                        className='weiterbutton'
                        onClick={() => {
                            setScreen(0)
                        }}> {language ? 'Back' : 'Zurück'}
                    </div>
                    <div
                        className='weiterbutton'
                        onClick={() => {
                            if (medikation == 1) {
                                setScreen(5)
                            } else if (steady == 0) {
                                setScreen(3)
                            } else if (steady == 1) {
                                setScreen(4)

                            }

                        }}> {language ? 'Next' : 'Weiter'}
                    </div>
                </div>
            }


        </div>
    )
}