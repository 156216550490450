import * as CONST from '../Constants.js';
import { useEffect, useState,  } from 'react';

export default function Warnhinweis({ language,  meropenemcl, E_meropenemCl, cminMHK, cMHK }) {
  const [warnung,setWarnung] = useState((cminMHK && ((cminMHK < 1) || (cminMHK > 4)))   || 
                                                  (cMHK && ((cMHK < 4) || (cMHK > 8)) ) || 
                                                  (meropenemcl && ((meropenemcl > E_meropenemCl * 2)    || (meropenemcl < E_meropenemCl * 0.5)))); 

  useEffect(() => {
    setWarnung((cminMHK &&  ((cminMHK < 1) || (cminMHK) > 4))||
     (cMHK && ((cMHK < 4) || (cMHK > 8))) || 
     (meropenemcl && ((meropenemcl > E_meropenemCl * 2) ||
        (meropenemcl < E_meropenemCl * 0.5))))
  }, [cminMHK, cMHK, meropenemcl, E_meropenemCl]) 

  return (
    warnung ?
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        
        width: CONST.BOXWIDTH,
        //height: extra ? '152px' : '223px',
        //maxHeight: extra ? '152px' : '223px',
        padding: CONST.PADDINGGROSS,
        margin: CONST.MARGINKLEIN,
        marginTop: CONST.MARGINGROSS,
        color: 'white', backgroundColor: '#A72134',
        borderRadius: 10,
      }}>


        <h2>
          {language ? 'WARNING' : 'WARNHINWEIS'}
        </h2>

        {cminMHK && ((cminMHK < 1) || (cminMHK) > 4) &&
          <p>{language ? 'Dosing outside target range' : 'cmin/MHK nicht im angestrebten Bereich'} </p>
        }

        {!!cMHK && ((cMHK < 4) || (cMHK > 8)) &&
          <p>{language ? 'Dosing outside target range' : 'c/MHK nicht im angestrebten Bereich'} </p>
        }

        {!!meropenemcl && ((meropenemcl > E_meropenemCl * 2) || (meropenemcl < E_meropenemCl * 0.5)) &&
          <div style={{ fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN }}>
            <p>{language ? 'Dose administered?' : 'Dosis gegeben?'}</p>
            <p>{language ? 'Dose omitted?' : 'Dosis vergessen?'}</p>
            <p>{language ? 'Time of blood sampling?' : 'Zeitpunkt der Blutabnahme?'}</p>
            <p>{language ? 'Sample take from access?' : 'Abnahme aus Zugang?'}</p>
            <p>{language ? 'Right patient?' : 'Patient verwechselt?'}</p>
          </div>
        }



        <div
          className='weiterbutton'
          onClick={() => {
            setWarnung(false)
          }}> OK </div>
      </div>
      : null
  )
}