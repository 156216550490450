
import * as CONST from '../Constants.js';
import '../App.css';
//import 'katex/dist/katex.min.css'
//import Latex from 'react-latex-next'
import formel1 from '../formel1.png'
import formel2 from '../formel2.png'
import formel3 from '../formel3.png'
import formel4 from '../formel4.png'
import formel5 from '../formel5.png'
import formel6 from '../formel6.png'
import formel7 from '../formel7.png'
import formel8 from '../formel8.png'
import formel8e from '../formel8e.png'
import formel9 from '../formel9.png'
import formel9e from '../formel9e.png'
import formel1e from '../formel1e.png'
import formel2e from '../formel2e.png'
import formel5e from '../formel5e.png'

export default function HinweiseScreen({
  language, screen, setScreen,
}) {

  return (
    <div style={{
      display: 'flex',


      flexDirection: 'column',
      marginTop: CONST.MARGINGROSS,
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
      color: 'white',
    }}>
      <div style={{ width: '80%', color: 'white', alignItems: 'center' }}>


        <h2 >
          {language ? 'About MeroEasy' : 'Über MeroEasy'}
        </h2>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          {language && <p style={{ width: window.innerWidth / 1.2 }}>
            MeroEasy is a training software developed for physicians and pharmacists to estimate influences of meropenem regimens and to
            understand pharmacokinetic formulas.
            This project was supported by the ADKA e.V. MeroEasy uses pharmacokinetic population estimates, the Dettli [1,2] and the Sawchuk-Zaske methods [1] to
            calculate meropenem dosing regimen. Meropenem regimens can be calculated both empirically (without any prior doses) or using meropenem levels in steady state.
            MeroEasy determines pharmacokinetic parameters and meropenem dosing strategies using the following steps:

            <h4>
              1. Estimate Cl and Vd
            </h4>

            Empiric dosing (no drug levels): Clearance (CL) and volume of distribution (Vd) are determined using population estimates
            from published literature (Vd = 0.7 L/kg, CLpop= 5.0 L/h) [3].
            Vd is assumed considering the ideal body weight plus 40% of the body mass above a patient’s ideal body weight.
            If the actual body weight is less than the ideal body weight, the actual body weight is used.
            Creatinine clearance is calculated using the Cockcroft Gault equation [1,4] with a 40% adjustment factor, or actual body weight,
            if actual body weight is below ideal body weight (cut-off serum creatinine 0.6 in women and 0.7 in men to prevent overestimation in cachectic patients):

            <h4>
              <img src={formel1e} style={{ width: 230, resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel2e} style={{ width: 230,  resizeMode: 'contain' }} />
            </h4>

            The dose adjustment factor Q is determined by the ratio of the patient’s eGFR to a presumed normal eGFR of 100 ml/min,
            based on estimation of the minimal elimination rate fraction in subjects with normal renal function (Q0 =0.25) [2,3], calculate Cl:
   
 
            <h4>
              <img src={formel3} style={{ width: 250,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel4} style={{ width: 110,  resizeMode: 'contain' }} />
            </h4>

            One drug level available (trough): Vd is assumed using a population estimate considering ideal body weight plus 40% of body mass
            above a patient’s ideal body weight. If the actual body weight is less than the ideal body weight, the actual body weight is used.
            The measured serum concentration is assigned to the corresponding half-life as a function of the infusion duration and the Cl is
            then calculated from these parameters.
            <h4>
              <img src={formel5e} style={{ width: 230,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel6} style={{ width: 100,  resizeMode: 'contain' }} />
            </h4>

            <h4>
              2. Loading dose
            </h4>
            A loading dose should be considered in order to achieve a rapid attainment of effective serum concentrations
            (500 mg as short infusion followed by immediate continuous infusion, 1000 mg as a short infusion in case of intermittent infusion) [5,6].

            <h4>
              3. Use one-compartment PK equations to estimate peak, trough, and c/MIC or cmin/MIC
            </h4>
            Once Cl, Vd, and Ke are determined, one-compartment pharmacokinetic equations can be used to determine the peak, trough, and AUC for a given regimen:
            <h4>
              <img src={formel7} style={{ width: 150,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel8e} style={{ width: 200,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel9e} style={{ width: 250,  resizeMode: 'contain' }} />
            </h4>

            Target range for meropenem concentration
            For continuous infusion: 4-6(8) x MIC [7]
            For intermittent infusion: 1-2(4) x MIC [8]
            MIC targets are based on MIC breakpoints from the European Committee of Antimicrobial Susceptibility and Testing (EUCAS) [9].
            All calculations assume MIC = 2 mg/L [9], but can be adjusted by entering the MIC. For infections with intermediate susceptible pathogens or CNS infections,
            doubling the target range is recommended (for continuous infusion c/MIC = 8-12 x MIC, for intermittent infusion cmin/MIC = 2-4 x MIC) [10].
            “Dosing outside of target range” may appear for intermittent infusion, if the computed dose has a cmin/MHK of less than 1 or greater
            than 4 and may appear for continuous infusion, if the computed dose has a c/MHK of less than 8 or greater than 16.
            The calculation of a "New Dose" is user-controlled and has no dose or infusion duration limitations.
            For continuous infusion, maintenance doses are limited to at least 250 mg/dose and at most to 16000 mg/dose.
            For intermittent infusion, maintenance doses are limited to at least 250 mg/dose and at most to 3000 mg/dose. The minimum dosing interval is q4h.

            Inappropriate Populations for this calculator:
            This calculator is NOT appropriate for the following patient populations:
            Renal replacement therapy (see dosing www.thecaddy.de instead), Unstable renal function, Meropenem MIC {'≥'} 8 mg/L, Pediatrics ({'<'} 18 years),
            Cystic fibrosis, Severe burn injury
            <h4>
              Disclaimer:
            </h4>
            MeroEasy has been created for personal use only. The use of any result generated by MeroEasy is in any case the sole risk and responsibility of the meropenem calculator user. Therapeutic decision should not solely rely on MeroEasy as information provided by MeroEasy does not replace clinical judgement. Users should seek a doctor’s advice in addition to using this app and before making any medical decisions. Although the MeroEasy has been validated carefully, there is no guarantee for the accuracy of the provided results.
            This app is provided by Dr Ute Chiriac, Heidelberg University Hospital & Dr Otto Frey, Heidenheim Hospital. Before continuing, please make sure you have read and understood the disclaimer.
            <h4>
              Literature
            </h4>
            <p>
              [1] Murphy JE.  Clinical pharmacokinetics. 7th Ed. American Society of Health Pharmacists (ASHP), 2021.
            </p>

            <p>
              [2] Dettli L. The kidney in pre-clinical and clinical pharmacokinetics. Jpn J Clin Pharmacol Ther 1984;15: 241-54
            </p>
            <p>
              [3] Summary of product characteristics Meronem®, Germany: May 2021.
            </p>
            <p>
              [4] Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron 1976;16: 31-41.
            </p>

            <p>
              [5] Liebchen U, Salletmeier H, Kallee S et al. Optimal loading dose of meropenem before continuous infusion in critically ill patients: a simulation study. Scientific reports 2021;11(1):17211.
            </p>
            <p>
              [6] Vorschläge des NAK zur Kommentierung von Ergebnissen der Resistenztestung, Version 3.0, Stand 12.10.2021: Available online at https://www.nak-deutschland.org
            </p>
            <p>
              [7] Gatti M, Cojutti PG, Bartoletti M et al. Expert clinical pharmacological advice may make an antimicrobial TDM program for emerging candidates more clinically useful in tailoring therapy of critically ill patients. Crit Care 2022;26(1):178.
            </p>
            <p>
              [8] Scharf C, Liebchen U, Paal M et al. The higher the better? Defining the optimal beta-lactam target for critically ill patients to reach infection resolution and improve outcome. J Intensive care 2020;8(1):86.
            </p>

            <p>
              [9] EUCAST. Antimicrobial wild type distributions of microorganisms for Meropenem. Available online at mic.eucast.org.
            </p>
            <p>
              [10] Tiede C, Chiriac U, Dubinski D et al. Cerebrospinal Fluid Concentrations of Meropenem and Vancomycin in Ventriculitis Patients Obtained by TDM-Guided Continuous Infusion. Antibiotics 2021;10(11):1421.
            </p>

          </p>
          }
          {!language && <p style={{ width: window.innerWidth / 1.2 }}>
            MeroEasy ist eine Lernsoftware, entwickelt mit Unterstützung der ADKA e.V. für die Ausbildung von Ärzten und Apothekern,
            um Einflüsse der Meropenem-Dosierung abschätzen und pharmakokinetische Formeln zu verstehen.
            MeroEasy verwendet zur Berechnung der Meropenem-Dosierung pharmakokinetische Populationsschätzungen, die Dettli-Methode [1,2] und die Sawchuk-Zaske-Methode [1].
            Meropenem-Dosierungen können sowohl empirisch (ohne vorherige Dosierung) als auch unter Verwendung von einem Meropenem-Spiegel berechnet werden.
            MeroEasy ermittelt pharmakokinetische Parameter und Meropenem-Dosierungsstrategien anhand der folgenden Schritte:

            <h4>
              1. Abschätzung von Cl und Vd
            </h4>

            Empirische Dosierung (kein Spiegel): Meropenem-Clearance (Cl) und Verteilungsvolumen (Vd) werden über Populationsdaten aus der Literatur
            bestimmt (Vd = 0.25 L/kg, CLpop = 13 L/h) [3].
            Vd wird unter Berücksichtigung des idealen Körpergewichts plus 40% des Übergewichts abgeschätzt.
            Bei einem aktuellen Körpergewicht unter dem idealen Körpergewicht wird das aktuelle Körpergewicht verwendet.
            Cl wird unter Berücksichtigung der Nierenfunktion abgeschätzt.
            Die Kreatinin-Clearance (CrCl) wird dafür über Cockcroft Gault [1,4] mit dem idealen Körpergewicht plus 40% des Übergewichts berechnet
            bzw. dem aktuellen Körpergewicht wenn das aktuelle Körpergewicht unter dem idealen Körpergewicht ist
            (cut-off Serumkreatinin 0,6 bei Frauen und 0,7 bei Männern um eine Überschätzung bei kachektischen Patienten zu verhindern):

            <h4>
              <img src={formel1} style={{ width: 250,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel2} style={{ width: 200,  resizeMode: 'contain' }} />
            </h4>
            Die Cl wird dann nach Dettli über die individuelle Ausscheidungskapazität Q (Q0 = 0.25) [2,3] berechnet:
            <h4>
              <img src={formel3} style={{ width: 250,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel4} style={{ width: 110,  resizeMode: 'contain' }} />
            </h4>
            Talspiegel vorhanden: Vd wird unter Berücksichtigung des idealen Körpergewichts plus 40% des Übergewichts abgeschätzt,
            bzw. dem aktuellen Körpergewicht, wenn das aktuelle Körpergewicht unter dem idealen Körpergewicht ist.
            Der gemessene Serumspiegel wird in Abhängigkeit von der Infusionsdauer der korrespondierenden Halbwertszeit zugeordnet und
            dann aus diesen Parametern die Cl berechnet.

            <h4>
              <img src={formel5} style={{ width: 250,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel6} style={{ width: 110, height: 20, resizeMode: 'contain' }} />
            </h4>
            <h4>
              2. Initialdosis
            </h4>
            Um ein schnelles Erreichen wirksamer Serumkonzentrationen zu erreichen, sollte eine Initialdosis in Betracht gezogen werden.
            Für die Dauerinfusion werden 500 mg, für die intermittierende Gabe 1000 mg als Kurzinfusion empfohlen [5,6].
            <h4>
              3. Ein-Kompartiment-Modelle zur Abschätzung von Talspiegel, Spitzenspiegel und c/MHK bzw. cmin/MHK
            </h4>

            Wenn Cl, Vd und Ke bestimmt sind, können anhand der Formeln für 1-Kompartiment-Modelle Talspiegel,
            Spitzenspiegel und Tagesdosis für eine Dosisstrategie bestimmt werden:

            <h4>
              <img src={formel7} style={{ width: 150,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel8} style={{ width: 200,  resizeMode: 'contain' }} />
            </h4>
            <h4>
              <img src={formel9} style={{ width: 250,  resizeMode: 'contain' }} />
            </h4>

            Zielbereich für die Meropenem-Konzentration
            <p>
              -	bei dauerhafter Gabe: 4-6(8) x MHK [7]
            </p>
            <p>
              -	bei intermittierender Gabe: 1-2(4) [8]
            </p>

            Die MHK-Ziele basieren auf den MHK-Breakpoints der European Committee of Antimicrobial Susceptibility and Testing (EUCAST) [9]. Alle Berechnungen gehen von MHK = 2 mg/L aus [9], können aber durch die Eingabe der MHK angepasst werden. Bei Infektionen mit i-getesteten Keimen oder ZNS-Infektionen wird eine Verdopplung der Zielwerte empfohlen (c/MHK = 8-12 bei Dauerinfusion, cmin/MHK = 2-4 bei intermittierender Gabe) [10].
            „cmin/MHK nicht im angestrebten Bereich“ kann auftreten, wenn die berechnete Dosis bei der intermittierenden Gabe eine cmin/MHK von weniger als 1 oder mehr als 4 aufweist. „c/MHK nicht im angestrebten Bereich“ kann auftreten, wenn die berechnete Dosis bei der Dauerinfusion eine c/MHK von weniger als 8 oder mehr als 16 aufweist. Die Berechnung einer „Neuen Dosierung“ ist benutzergesteuert und hat keine Dosis- oder Infusionsdauereinschränkungen. Die Erhaltungsdosen sind auf mindestens 250 mg/Dosis und maximal 16000 mg/Dosis bei dauerhafter Gabe bzw. 3000 mg/Dosis bei intermittierender Gabe sowie das Dosierungsintervall bei intermittierender Gabe auf mindestens 4 h begrenzt.

            Ungeeignete Patientenpopulationen für diese Berechnungen:
            Die verwendeten pharmakokinetischen Formeln sind für die folgenden Patientenpopulationen NICHT geeignet:
            Nierenersatzverfahren (Berechnung der Erhaltungsdosis mit www.thecaddy.de möglich), instabile Nierenfunktion, Meropenem-MHK ≥ 8 mg/l,  Pädiatrie ({'<'} 18 Jahre), Mukoviszidose, schwere Verbrennungsverletzung
            <h4>
              Haftungsausschluss:
            </h4>
            MeroEasy wurde nur für den persönlichen Gebrauch erstellt. Die Verwendung eines von MeroEasy erzeugten Ergebnisses erfolgt in jedem Fall auf alleiniges Risiko und Verantwortung des MeroEasy-Benutzers. Therapeutische Entscheidungen sollten nicht ausschließlich auf MeroEasy beruhen, da die von MeroEasy bereitgestellten Informationen die klinische Beurteilung nicht ersetzen. MeroEasy-Benutzer sollten zusätzlich zur Verwendung dieser App und vor medizinischen Entscheidungen einen Arzt konsultieren. Obwohl MeroEasy sorgfältig validiert wurde, gibt es keine Garantie für die Richtigkeit der bereitgestellten Ergebnisse.
            Diese App wird bereitgestellt von Dr. Ute Chiriac, Universitätsklinikum Heidelberg & Dr. Otto Frey, Klinikum Heidenheim. Bevor Sie fortfahren, vergewissern Sie sich bitte, dass Sie den Haftungsausschluss gelesen und verstanden haben.

            <h4>
              Literatur
            </h4>
            <p>
              [1] Murphy JE.  Clinical pharmacokinetics. 7th Ed. American Society of Health Pharmacists (ASHP), 2021.
            </p>

            <p>
              [2] Dettli L. The kidney in pre-clinical and clinical pharmacokinetics. Jpn J Clin Pharmacol Ther 1984;15: 241-54
            </p>

            <p>
              [3] Fachinformation Meronem®, Deutschland. Stand: Mai 2021.
            </p>

            <p>
              [4] Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron 1976; 16: 31–41.
            </p>

            <p>
              [5] Liebchen U, Salletmeier H, Kallee S et al. Optimal loading dose of meropenem before continuous infusion in critically ill patients: a simulation study. Scientific reports 2021;11(1):17211.
            </p>

            <p>
              [6] Vorschläge des NAK zur Kommentierung von Ergebnissen der Resistenztestung, Version 3.0, Stand 12.10.2021: Available online at https://www.nak-deutschland.org
            </p>

            <p>
              [7] Gatti M, Cojutti PG, Bartoletti M et al. Expert clinical pharmacological advice may make an antimicrobial TDM program for emerging candidates more clinically useful in tailoring therapy of critically ill patients. Crit Care 2022;26(1):178.
            </p>

            <p>
              [8] Scharf C, Liebchen U, Paal M et al. The higher the better? Defining the optimal beta-lactam target for critically ill patients to reach infection resolution and improve outcome. J Intensive Care 2020;8(1):86.
            </p>

            <p>
              [9] EUCAST. Antimicrobial wild type distributions of microorganisms for Meropenem. Available online at mic.eucast.org.
            </p>

            <p>
              [10] Tiede C, Chiriac U, Dubinski D et al. Cerebrospinal Fluid Concentrations of Meropenem and Vancomycin in Ventriculitis Patients Obtained by TDM-Guided Continuous Infusion. Antibiotics 2021;10(11):1421.
            </p>




          </p>
          }

          {/*


    {language ? 'Empiric dosing (no drug levels): Clearance (CL) and volume of distribution (Vd) are determined using population estimates from published literature (Vd = 0.7 L/kg, CLpop= 5.0 L/h) [3]. Vd is assumed considering the ideal body weight plus 40% of the body mass above a patient’s ideal body weight. If the actual body weight is less than the ideal body weight, the actual body weight is used. Creatinine clearance is calculated using the Cockcroft Gault equation [1,4] with a 40% adjustment factor, or actual body weight, if actual body weight is below ideal body weight (cut-off serum creatinine 0.6 in women and 0.7 in men to prevent overestimation in cachectic patients):' : 'Empirische Dosierung (kein Spiegel): Meropenem-Clearance (Cl) und Verteilungsvolumen (Vd) werden über Populationsdaten aus der Literatur bestimmt (Vd = 0.25 L/kg, CLpop = 13 L/h) [3]. Vd wird unter Berücksichtigung des idealen Körpergewichts plus 40% des Übergewichts abgeschätzt. Bei einem aktuellen Körpergewicht unter dem idealen Körpergewicht wird das aktuelle Körpergewicht verwendet. Cl wird unter Berücksichtigung der Nierenfunktion abgeschätzt. Die Kreatinin-Clearance (CrCl) wird dafür über Cockcroft Gault [1,4] mit dem idealen Körpergewicht plus 40% des Übergewichts berechnet bzw. dem aktuellen Körpergewicht wenn das aktuelle Körpergewicht unter dem idealen Körpergewicht ist (cut-off Serumkreatinin 0,6 bei Frauen und 0,7 bei Männern um eine Überschätzung bei kachektischen Patienten zu verhindern):'}
    <h5>
      {!language ? <img src={formel1} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel11} style={{ margin: MARGINKLEIN, width: '80%', }} />}
    </h5>

    <h5>
      {!language ? <img src={formel2} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel12} style={{ margin: MARGINKLEIN, width: '80%', }} />}
    </h5>
    {language ? 'Determine the dose adjustment factor Q by the ratio of the patient’s eGFR to a presumed normal eGFR of 100 ml/min based on estimation of the minimal elimination rate fraction in subjects with normal renal function (Q0 = 0.13) [1,4], calculate Cl:' : 'Die Cl wird dann nach Dettli über die individuelle Ausscheidungskapazität Q (Q0 = 0.13) [1,4] berechnet:'}
    <h5>

      {!language ? <img src={formel3} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel13} style={{ margin: MARGINKLEIN, width: '80%', }} />}
    </h5>

    <h5>   {!language ? <img src={formel4} style={{ margin: MARGINKLEIN, width: '50%', }} /> : <img src={formel14} style={{ margin: MARGINKLEIN, width: '50%', }} />}</h5>


    {language ? 'One drug level available (trough): Vd is assumed using a population estimate considering actual body weight. Clearance is then determined extrapolating a peak value, calculate ke:' : 'Talspiegel vorhanden: Vd wird unter Berücksichtigung des aktuellen Körpergewichts abgeschätzt. Cl wird dann über die Extrapolation des Spitzenspiegels und die Berechnung von ke bestimmt.'}
    <h5>
      {!language ? <img src={formel5} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel15} style={{ margin: MARGINKLEIN, width: '80%', }} />}
    </h5>
    <h5>
      {!language ? <img src={formel6} style={{ margin: MARGINKLEIN, width: '50%', }} /> : <img src={formel16} style={{ margin: MARGINKLEIN, width: '50%', }} />}
    </h5>










    <h4>
      {language ? '2. Loading dose' : ' 2. Initialdosis'}
    </h4>
    {language ? 'A loading dose be considered in order to achieve rapid attainment of serum concentrations (target concentration 25 mg/L) [1,5]. Using a population estimate, calculate dose:' : 'Um ein schnelles Erreichen der Serumkonzentration zu erreichen, sollte eine Initialdosis in Betracht gezogen werden. Das Konzentrationsziel beträgt 25 mg/L [1,5]. Die Dosis wird mit Vd über Populationsdaten berechnet.'}
    <h5>
      {!language ? <img src={formel7} style={{ margin: MARGINKLEIN, width: '50%', }} /> : <img src={formel17} style={{ margin: MARGINKLEIN, width: '50%', }} />}
    </h5>
    <h4>
      {language ? '3. Use one-compartment PK equations to estimate peak, trough, and AUC' : '3. Ein-Kompartiment-Modelle zur Abschätzung von Talspiegel, Spitzenspiegel und AUC '}
    </h4>


    {language ? 'Once Cl, Vd, and Ke are determined, one-compartment pharmacokinetic equations can be used to determine the peak, trough, and AUC for a given regimen:' : 'Wenn Cl, Vd und Ke bestimmt sind, können anhand der Formeln für 1-Kompartiment-Modelle Talspiegel, Spitzenspiegel und Tagesdosis für eine Dosisstrategie bestimmt werden.'}
    <h5>
      {!language ? <img src={formel8} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel18} style={{ margin: MARGINKLEIN, width: '80%', }} />}

    </h5>
    <h5>
      {!language ? <img src={formel9} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel19} style={{ margin: MARGINKLEIN, width: '80%', }} />}

    </h5>
    <h5>

      {!language ? <img src={formel10} style={{ margin: MARGINKLEIN, width: '80%', }} /> : <img src={formel20} style={{ margin: MARGINKLEIN, width: '80%', }} />}
    </h5>

    {language ? 'The calculated AUC goal is 500 mg*h/L [5]. All calculations assume MIC =1 mg/L [5,6]. “Empiric dosing” is limited to a loading dose of 2000 mg [4], maintenance doses are limited to at least 500 mg/dose for intermittent infusion. The minimum dosing interval is q6h for intermittent infusion. New or adjusted dosing for intermittent infusion are calculated using infusion rate of 1000 mg/h [4]. “Dosing outside of target range” may appear if the computed dose has an AUC under 400 or greater than 600 [5]. The “alternate dosing” calculator is user-driven and has no dosing or frequency limitations. ' : ' Das berechnete AUC-Ziel ist 500 mg*h/L [5]. Alle Berechnungen gehen von MIC =1 mg/L [5,6] aus. Die "empirische Dosierung" ist auf eine Initialdosis von 2000 mg [4] begrenzt, die Erhaltungsdosen sind bei intermittierender Infusion mindestens 500 mg/Dosis und das Dosierungsintervall mindestens 6h. Neue oder angepasste Dosierungen für die intermittierende Infusion werden mit einer Infusionsrate von 1000 mg/h [4] berechnet. „AUC nicht im angestrebten Bereich“ kann auftreten, wenn die berechnete Dosis eine AUC von weniger als 400 oder mehr als 600 aufweist [5]. Die Berechnung einer „Anderen Dosierung“ ist benutzergesteuert und hat keine Dosis- oder Dosisintervalleinschränkungen.'}
    <h4>
      {language ? 'Inappropriate Populations for this calculator: ' : ' Ungeeignete Patientenpopulationen für diese Berechnungen:'}
    </h4>
    {language ? 'This calculator is NOT appropriate for the following patient populations: ' : 'Die verwendeten pharmakokinetischen Formeln sind für die folgenden Patientenpopulationen NICHT geeignet:'}


    {language ? 'Renal replacement therapy (see dosing www.thecaddy.de instead), Unstable renal function, Vancomycin MIC ≥ 2 mg/L, Pediatrics (< 18 years), Cystic fibrosis, Severe burn injury' : 'Nierenersatzverfahren (Berechnung der Erhaltungsdosis mit www.thecaddy.de möglich), Instabile Nierenfunktion, Vancomycin-MIC ≥ 2 mg/l, Pädiatrie (unter 18 Jahre), Mukoviszidose, Schwere Verbrennungsverletzung'

    }
    <h4>
      {language ? 'Disclaimer:' : 'Haftungsausschluss:'}
    </h4>

    {language ? ' VancoEasy has been created for personal use only. The use of any result generated by VancoEasy is in any case the sole risk and responsibility of the Vancomycin calculator user. Therapeutic decision should not solely rely on VancoEasy as information provided by VancoEasy does not replace clinical judgement. Users should seek a doctor’s advice in addition to using this app and before making any medical decisions. Users should seek a doctor’s advice in addition to using this app and before making any medical decisions. Although the VancoEasy has been validated carefully, there is no guarantee for the accuracy of the provided results. This app is provided by Dr Ute Chiriac, Heidelberg University Hospital & Dr Otto Frey, Heidenheim Hospital. Before continuing, please make sure you have read and understood the disclaimer.' : ' VancoEasy wurde nur für den persönlichen Gebrauch erstellt. Die Verwendung eines von VancoEasy erzeugten Ergebnisses erfolgt in jedem Fall auf alleiniges Risiko und Verantwortung des VancoEasy-Benutzers. Therapeutische Entscheidungen sollten nicht ausschließlich auf VancoEasy beruhen, da die von VancoEasy bereitgestellten Informationen die klinische Beurteilung nicht ersetzen. VancoEasy-Benutzer sollten zusätzlich zur Verwendung dieser App und vor medizinischen Entscheidungen einen Arzt konsultieren. Obwohl VancoEasy sorgfältig validiert wurde, gibt es keine Garantie für die Richtigkeit der bereitgestellten Ergebnisse. Diese App wird bereitgestellt von Dr. Ute Chiriac, Universitätsklinikum Heidelberg & Dr. Otto Frey, Klinikum Heidenheim. Bevor Sie fortfahren, vergewissern Sie sich bitte, dass Sie den Haftungsausschluss gelesen und verstanden haben.'}

    <h4>
      {language ? 'Literature:' : 'Literatur'}
    </h4>

    <p>
      {'[1] Murphy JE.  Clinical pharmacokinetics. 7th Ed. American Society of Health Pharmacists (ASHP), 2021.'}

    </p>

    <p>
      {'[2] Dettli L. The kidney in pre-clinical and clinical pharmacokinetics. Jpn J Clin Pharmacol Ther 1984;15: 241-54'}

    </p>

    <p>
      {'[3] Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron 1976; 16: 31–41.'}

    </p>

    <p>
      {language ? '[4] Summary of product characteristics Vancomycin CP 1.0 g®, Germany: December 2020. ' : '[4] Fachinformation Vancomycin CP 1.0 g®, Deutschland. Stand: Dezember 2020.'}

    </p>
    <p>
      {'[5] Rybak MJ, Le J, Lodise TP, et al. Therapeutic monitoring of vancomycin for serious methicillin-resistant Staphylococcus aureus infections: A revised consensus guideline and review of the American Society of Health-System Pharmacists, the Infectious Diseases Society of America, the Pediatric Infectious Diseases Society and the Society of Infectious Diseases Pharmacists. Accessed April 2020. Available online at IDsociety.org.'}

    </p>
    <p>
      {'[6] EUCAST. Antimicrobial wild type distributions of microorganisms for Vancomycin. Available online at mic.eucast.org.'}

    </p>*/}

        </div>

      </div>



    </div>
  )
}