import * as CONST from '../Constants.js';

export default function InfoDrei({ titel, infos, ergebnisse, units, colors, variable, setVariable, zusatz, extra }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: CONST.BOXWIDTH,
    height: (extra && extra != true) ? '152px': CONST.BOXHEIGHT + (infos.length-1) * 26 + 'px',
    maxHeight: (extra && extra != true) ? '152px':CONST.BOXHEIGHT + (infos.length-1) * 26 + 'px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: CONST.MARGINKLEIN,
    backgroundColor: 'rgba(83, 158, 123, 0.664)',
    borderRadius: 10,
  }}>

    <div style={{
      display: 'flex',
      marginTop: CONST.MARGINKLEIN,
      marginBottom: extra? 0: CONST.MARGINKLEIN,
      flexDirection: 'row',
      justifyContent: 'center',
      color: 'black', 
      fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN,
    }} >
      {zusatz}
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN,
      color: 'black',
      marginBottom: (extra && extra != true) ? CONST.MARGINKLEIN:0 ,
    }} >
      {extra}
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }} >
      {infos.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: CONST.FIELDWITH,         
            height:  CONST.FIELDHEIGHT,
            fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN,
            marginRight: CONST.MARGINKLEIN,
            marginLeft: CONST.MARGINKLEIN,
            marginBottom: CONST.MARGINKLEIN,
            justifyContent: 'flex-start'
          }}>
          <div className={((index != 1 && extra) ) ? 'info' : 'infomiddle'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              height:  CONST.FIELDHEIGHT, 
              backgroundColor: colors? colors[index] : '',
              paddingVertical: 0,
            }}>
   
              <div style={{
                display: 'flex', justifyContent: 'flex-end',
                width: '50%',
                padding: 0,
                color: '#142D22',
                fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN, fontWeight: ((index != 1 && extra) || infos.length == 2) ? 400 : 400,
                wordBreak: 'break-all'
              }}>
                {info}
              </div>
              <div style={{marginLeft: CONST.MARGINGROSS}}>
                {ergebnisse[index]}  {units[index]} 
              </div>
              <div style={{
                marginLeft: CONST.MARGINGROSS,
                fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN
              }}>
                {extra ?'(' + Math.round(2.1 * ergebnisse[index] / 100) / 10 + ' ml/h)' : null} 
              </div>
          </div>
        </div>
      })}
    </div>

    <div style={{ fontFamily:'Inter', fontSize: CONST.SCHRIFTMITTEL, }}>
      {titel}
    </div>

  </div>
}